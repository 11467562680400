import React from "react"

interface ButtonProps {
    text: string
    className?: string
    style?: React.CSSProperties
}

const Button = ({ text, className, style }: ButtonProps) => {
    return (
        <a className={"dib ph3 pv3 br2 b " + className}
            style={{ background: "#ff0000", color: "#fff", ...style }}>
            {text}
        </a>
    )
}

export default Button
