import React, { useState, useEffect, CSSProperties } from "react"
import { motion, useAnimation, useTransform, MotionValue, useMotionValue } from "framer-motion"

interface GraphProps {
    perc: MotionValue // number between 0 and 1
}

const Graph = ({ perc }: GraphProps) => {
    return (
        <svg viewBox="0 0 200 100" width="100%" preserveAspectRatio="xMidYMid">
            <g id="layer1">
                <motion.path
                    style={{
                        pathLength: perc,
                        strokeWidth: 1,
                        stroke: "rgb(255, 255, 255, 0.8)",
                        fill: "none",
                    }}
                    d="M -10,60 30,42 l 20,22 65,-42 5,7 87,-30" />
                <Dot cx={30} cy={42} perc={perc} treshold={0.18} />
                <Dot cx={50} cy={64} perc={perc} treshold={0.3} />
                <Dot cx={115} cy={22} perc={perc} treshold={0.60} />
                <Dot cx={120} cy={29} perc={perc} treshold={0.63} />
            </g>
        </svg>
    )
}

interface DotProps {
    cx: number,
    cy: number,
    perc: MotionValue,
    treshold: number,
}

const Dot = ({ cx, cy, perc, treshold }: DotProps) => {
    const dotVariants = {
        hidden: {
            cx,
            cy,
            r: 0,
            fill: "rgba(255, 255, 255, 0)",
        },
        visible: {
            cx,
            cy,
            r: 1.2,
            fill: "rgb(255, 255, 255, 0.9)",
        }
    }

    const controls = useAnimation()
    let visible = false

    useEffect(() => {
        function update() {
            if (perc.get() > treshold && !visible) {
                visible = true
                controls.start("visible")
            }
            if (perc.get() < treshold && visible) {
                visible = false
                controls.start("hidden")
            }
        }

        const unsubscribe = perc.onChange(update)

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <motion.circle
            variants={dotVariants}
            initial="hidden"
            animate={controls} />
    )
}

export default Graph
