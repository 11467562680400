import React from "react"

import { SITE_MAX_WIDTH } from "./center"

// A Block is used on the homepage it represents a single max-width section
export interface BlockProps {
    className?: string,
    highlightColorSelect?: string
    style?: React.CSSProperties,
    children: JSX.Element[] | string,
}

const Block = (props: BlockProps) => {
    const defaultClasses = "block mt5-l pt5-l ph0-l ph4 ml-auto mr-auto lh-copy f3-ns f4 relative flex flex-column"
    const defaultStyle = {
        marginBottom: 300,
        color: "#888",
        maxWidth: SITE_MAX_WIDTH,
        "--highlight-color-select": props.highlightColorSelect
    }
    const classes = (props.className) ? defaultClasses + " " + props.className : defaultClasses

    return (
        <section className={classes} style={{ ...defaultStyle, ...props.style }}>
            {props.children}
        </section>
    )
}

export default Block
