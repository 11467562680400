import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { motion, useViewportScroll, useTransform, useMotionValue, useElementScroll, MotionValue } from "framer-motion"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Block from "../components/block"
import LogoText from "../components/logo-text"
import Button from "../components/button"
import Graph from "../components/graph-animation"
import Title, { Highlight } from "../components/title"
import FlowChart from "../components/flowchart"
import { linearGradient } from "../util/color"

// Transforms scrollY relative to the reference (section) element when centered on screen
const transformScrollSection = (ref: React.RefObject<HTMLElement>) => (scrollY: number) => {
    if (!ref.current) {
        return 0
    }

    const browserHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    const offsetTop = ref.current.offsetTop
    const height = ref.current.clientHeight

    const localOffset = (scrollY - offsetTop) + browserHeight
    const p = localOffset / (browserHeight + height)

    if (p < 0) {
        return 0
    }

    if (p > 1) {
        return 1
    }

    return p
}

const darkGray = "#333333"
const turqoise = "#27d87c"
const purple = "#9c02fc"
const yellow = "#ffcc00"
const blue = "#1725ff"
const pink = "#fc74c1"
const red = "#fc3d2f"

const IndexPage = () => {
    const HEADER_OFFSET = 100

    const { scrollYProgress, scrollY } = useViewportScroll()
    const colorImplicit = useTransform(scrollYProgress, [0, 0.15], [darkGray, turqoise])
    const colorExplicit = useTransform(scrollYProgress, [0, 0.15], [turqoise, darkGray])
    const gradImplicit = useTransform(colorImplicit, linearGradient)
    const gradExplicit = useTransform(colorExplicit, linearGradient)
    const headerMenuOpacity = useTransform(scrollYProgress, [0, 0.5], [0, 1])
    const headerTop = useMotionValue(HEADER_OFFSET)
    const headerPos = useMotionValue("absolute")

    const graphRef = useRef(null)
    const graphDelta = useTransform(scrollY, transformScrollSection(graphRef))
    const problemSolversRef = useRef(null)
    const problemSolversDelta = useTransform(scrollY, transformScrollSection(problemSolversRef))
    const flowChartRef = useRef(null)
    const flowChartDelta = useTransform(scrollY, transformScrollSection(flowChartRef))

    // These are the yellow tags that float paralaxed
    const jobTitles = [
        { name: "Data Eningeer", x: 0.458, y: 0.2, z: 0.8, bg: "#FC0" },
        { name: "Data Analyst", x: 0, y: 0.1, z: 0.9, bg: "#F5B000" },
        { name: "Data Scientist", x: 0.7, y: 0.3, z: 0.1, bg: "#DE8F0B" },
        { name: "Project Manager", x: 0.8, y: 0.6, z: 0.3, bg: "#FC0" },
        { name: "Database Manager", x: -0.1, y: 0.88, z: 0.4, bg: "#FC0" },
        { name: "Security Specialist", x: -0.21, y: 0.5, z: 0.3, bg: "#DE8F0B" },
        { name: "Programmer", x: 0.6, y: 0.98, z: 0.2, bg: "#FA8000" },
        { name: "Database Eningeer", x: 0.3, y: 0.98, z: 0.1, bg: "#FC0" },
    ].map(item => {
        const opacity = useTransform(problemSolversDelta, [0, 0.55, 1], [0, 1, 0])
        const pix = item.y * 1000
        const top = useTransform(problemSolversDelta, [0, 1], [-0.9 * pix * item.z, 0.6 * pix * item.z])
        const blurRatio = 1 / item.z
        const blur = useTransform(problemSolversDelta, [0, 0.4, 0.6, 1], [1 * blurRatio, 0, 0, 1 * blurRatio])
        const filterBlur = useTransform(blur, (n: number) => { return `blur(${n}px)` })
        const fontSize = 30 - (40 * item.z)
        return (
            <motion.div
                key={item.name}
                className="dib pv1 ph3 b f4 absolute"
                style={{
                    opacity,
                    fontSize,
                    color: "#222",
                    background: "#FC0",
                    borderRadius: 50,
                    left: `${item.x * 100}%`,
                    top: `${item.y * 100}%`,
                    translateY: top,
                    filter: filterBlur,
                    whiteSpace: "nowrap",
                }}>
                {item.name}
            </motion.div>
        )
    })

    useEffect(() => {
        function updateHeader() {
            if (scrollY.get() > HEADER_OFFSET && headerPos.get() === "absolute") {
                headerPos.set("fixed")
                headerTop.set(0)
            } else if (scrollY.get() < HEADER_OFFSET && headerPos.get() === "fixed") {
                headerPos.set("absolute")
                headerTop.set(HEADER_OFFSET)
            }
        }

        const unsubscribe = scrollY.onChange(updateHeader)

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <>
            <SEO title="Home" theme="dark" lang="nl" />
            <Layout headerTop={headerTop} headerPosition={headerPos} headerMenuOpacity={headerMenuOpacity}>

                <Block className="flex flex-column" style={{ maxWidth: 800 }} highlightColorSelect={turqoise}>
                    <div className="flex flex-column">
                        <Title className="hero">
                            Wij maken<br />
                            <motion.mark style={{ "--highlight-color": gradImplicit }}>onbewuste</motion.mark><br />
                            kennis<br />
                            <motion.mark style={{ "--highlight-color": gradExplicit }}>bewust</motion.mark><br />
                        </Title>
                    </div>
                    <div className="measure lh-copy f3" style={{ color: "#888" }}>
                        <strong>Tacitic</strong> (tac-​it-ic | \ta-sət-ik\) is
                        afgeleid van het Engelse <strong>Tacit knowledge</strong>,
                        dat onbewuste of impliciete kennis betekent. Voorbeelden
                        hiervan zijn: handelingen en intuities. <br /><br />Data bevat
                        tegenwoordig steeds meer onbewuste kennis en informatie. Dit
                        onstaat door segmentatie in verschillende databronnen of
                        doordat het vergeten of ongebruikt is (dark data). Het is
                        onze missie om deze onbewuste kennis weer bewust te maken.
                    </div>
                </Block>

                <div className="relative overflow-hidden" ref={graphRef}>
                    <div
                        className="absolute flex items-center dn db-ns"
                        style={{ width: "120%", height: "120%", left: "-10%", top: "-7%" }}>
                        <Graph perc={useTransform(graphDelta, [0, 0.5], [0, 1])} />
                    </div>
                    <Block highlightColorSelect={purple} className="items-end tr">
                        <Title highlightColor={purple} maxWidth={700}>
                            Werken <br /> vanuit een <br /><Highlight>data vraagstuk</Highlight>
                        </Title>
                        <p className="measure pl4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nam ut dui et ipsum pharetra rutrum. Sed vel
                            mollis dui. Nulla tincidunt ligula sed lobortis
                            tincidunt. Nulla porttitor laoreet ante, nec rutrum
                            sapien dignissim sed. Sed et tellus et libero
                            iaculis rhoncus non ac tellus.
                            </p>
                    </Block>
                </div>

                <div ref={problemSolversRef}>
                    <Block highlightColorSelect={yellow}>
                        <div className="absolute" style={{ width: "120%", height: "120%", top: "-10%", left: "-10%" }}>
                            {jobTitles}
                        </div>
                        <Title highlightColor={yellow}>
                            Problemen zijn er om<br /> <Highlight>op te lossen</Highlight>
                        </Title>
                        <p className="measure">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nam ut dui et ipsum pharetra rutrum. Sed vel
                            mollis dui. Nulla tincidunt ligula sed lobortis
                            tincidunt. Nulla porttitor laoreet ante, nec rutrum
                            sapien dignissim sed. Sed et tellus et libero
                            iaculis rhoncus non ac tellus.
                        </p>
                    </Block>
                </div>

                <div ref={flowChartRef}>
                    <Block highlightColorSelect={purple} className="items-end tr">
                        <Title highlightColor={purple}>
                            Van vraag naar <Highlight>antwoord</Highlight>
                        </Title>
                        <p className="measure">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nam ut dui et ipsum pharetra rutrum. Sed vel
                            mollis dui. Nulla tincidunt ligula sed lobortis
                            tincidunt. Nulla porttitor.
                        </p>
                        <FlowChart scrollY={flowChartDelta} />
                    </Block>
                </div>

                <Block highlightColorSelect={red}>
                    <Title highlightColor={red}>
                        Kennis <Highlight>overdragen</Highlight>
                    </Title>
                    <p className="measure">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam ut dui et ipsum pharetra rutrum. Sed vel mollis dui.
                        Nulla tincidunt ligula sed lobortis tincidunt. Nulla
                        porttitor laoreet ante, nec rutrum sapien dignissim sed.
                        Sed et tellus et libero iaculis rhoncus non ac tellus.
                    </p>
                </Block>

                <Block highlightColorSelect={pink} className="items-end tr">
                    <Title highlightColor={pink}>
                        Voor en<br /> door een <Highlight>community</Highlight>
                    </Title>
                    <p className="measure">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam ut dui et ipsum pharetra rutrum. Sed vel mollis dui.
                        Nulla tincidunt ligula sed lobortis tincidunt. Nulla
                        porttitor laoreet ante, nec rutrum sapien dignissim sed.
                        Sed et tellus et libero iaculis rhoncus non ac tellus.
                    </p>
                </Block>

                <Block style={{ padding: 0, marginBottom: 0 }} highlightColorSelect={blue}>
                    <div className="flex">
                        <div>
                            <Title highlightColor={blue}>
                                Combineer het met <Highlight>Flux</Highlight>
                            </Title>
                            <p className="measure">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Nam ut dui et ipsum pharetra
                                rutrum. Sed vel mollis dui. Nulla tincidunt
                                ligula sed lobortis tincidunt. Nulla porttitor
                                laoreet ante, nec rutrum sapien dignissim sed.
                                Sed et tellus et libero iaculis rhoncus non ac
                                tellus. Vindt meer informatie over Flux <Link to="/flux">hier</Link>
                            </p>
                        </div>
                        <div className="flex flex-colunm items-center flex-grow">
                            <Button text="Plan een demo" className="ml-auto mr-auto" style={{ background: blue }} />
                        </div>
                    </div>
                    <div
                        className="flux-screenshot mt5 relative"
                        style={{ width: "120%", left: "-10%", height: 530, background: "#232323", borderRadius: "10px 10px 0 0" }} />
                </Block>
            </Layout >
        </>
    )
}

export default IndexPage
