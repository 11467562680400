import React, { useState, useEffect, CSSProperties } from "react"
import { motion, useAnimation, useSpring } from "framer-motion"
import { linearGradient } from "../util/color"

export interface FlowChartProps {
    scrollY: motionValue,
}

export interface PointProps {
    x: number
    y: number
    component?: JSX.Element
}

// TODO: Borrowed from index.tsx, move this to a general location
const darkGray = "#333333"
const turqoise = "#27d87c"
const purple = "#9c02fc"
const yellow = "#ffcc00"
const blue = "#1725ff"
const pink = "#fc74c1"
const red = "#fc3d2f"

const FlowChart = ({ scrollY }: FlowChartProps) => {
    const scale = 0.85
    const offsetX = 40
    const offsetY = 40
    const points = [
        { x: 5, y: 65, component: <Bubble title="Vraag" color={purple} grad={linearGradient(purple, -0.4, -90)} /> },
        { x: 600, y: 65, component: <POI /> },
        { x: 955, y: 65 },
        { x: 955, y: 160, component: <Bubble title="Hypothese" color={turqoise} grad={linearGradient(turqoise, -0.4, 90)} /> },
        { x: 955, y: 300 },
        { x: 605, y: 300, component: <Bubble title="Audit" color={turqoise} grad={linearGradient(turqoise, -0.4, 90)} /> },
        { x: 605, y: 600, component: <Bubble title="Aggregatie" color={blue} grad={linearGradient(blue, -0.4, 90)} /> },
        { x: 605, y: 1000 },
        { x: 480, y: 1000, component: <Bubble title="Analyse" color={yellow} grad={linearGradient(yellow, -0.4, 120)} /> },
        { x: 355, y: 1000 },
        { x: 355, y: 750, component: <Bubble title="Kennis" color={red} grad={linearGradient(red, -0.4, -90)} /> },
        { x: 355, y: 300 },
        { x: 130, y: 300, component: <Bubble title="Antwoord" color={purple} grad={linearGradient(purple, -0.4, -90)} /> },
        { x: 5, y: 300 },
        { x: 5, y: 65 }
    ].map(p => ({ ...p, x: (p.x * scale) + offsetX, y: (p.y * scale) + offsetY }))

    const components: JSX.Element[] = points.filter(p => p.component).map((p, i) => {
        return (
            <div key={`p-${i}`} style={{
                top: p.y,
                left: p.x,
                position: "absolute",
                transform: "translate(-50%, -50%)"
            }}>
                {p.component}
            </div>
        )
    })

    const strokeAnimation = {
        normal: {
            strokeDashoffset: 0,
        },
        running: {
            strokeDashoffset: -90,
            transition: {
                duration: 2,
                loop: Infinity,
                ease: "easeInOut"
            }
        }
    }

    const strokeAnimationControl = useAnimation()
    let running = false

    useEffect(() => {
        function update() {
            if (scrollY.get() > 0 && !running) {
                running = true
                strokeAnimationControl.start("running")
            }

            if ((scrollY.get() >= 1 || scrollY.get() === 0) && running) {
                running = false
                strokeAnimationControl.stop()
            }
        }

        const unsubscribe = scrollY.onChange(update)

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <div className="w-100 relative">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 960 1010"
                style={{ width: "100%" }}>
                <g strokeLinecap="round">
                    <motion.g>
                        <motion.path
                            fill="none"
                            stroke="#fff"
                            strokeWidth="5"
                            strokeDasharray="10,20"
                            strokeDashoffset="0"
                            variants={strokeAnimation}
                            animate={strokeAnimationControl}
                            d={createPath(points)}
                        />
                    </motion.g>
                </g>
            </svg>
            {components}
        </div>
    )
}


const createPath = (points: Point[]) => {
    return points.reduce((acc: string, p: Point, idx: number) =>
        acc + ((idx === 0) ? `M${p.x},${p.y}` : `L${p.x},${p.y}`)
        , '')
}

export default FlowChart

interface BubbleProps {
    title: string
    grad: string
}

const Bubble = ({ title, color, grad }: BubbleProps) => {
    return (
        <div className="bg-pink ph4 pv1 br4 white tc" style={{
            background: grad,
        }}>
            <h3>{title}</h3>
        </div>
    )
}

const POI = () => {
    return (
        <motion.div
            className="bg-white"
            style={{
                width: 30,
                height: 30,
                borderRadius: 50,
            }}
            animate={{
                width: 38,
                height: 38,
                transition: {
                    spring: {
                        damping: 1,
                        stiffness: 100,
                    },
                    yoyo: Infinity,
                }
            }}
        />
    )
}
